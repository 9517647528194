@import '../../sass/utils.scss';

.card {
    height: 150px;
    width: 100%;
    margin: 20px 0;
    font-weight: $title-font;
    background-color: #eeeded;
    border-radius: 10px;
    &__container {
        display: flex;
        justify-content: flex-start;
        padding: 50px 50px;
    }
    &__icon {
        color: $brand-purple;
        font-size: 40px;
    }
    &__info {
        margin-left: 10px;
        color: rgb(51, 51, 51);
    }
    &__info h3 {
        font-size: 18px;
    }
    &__info p {
        font-size: 14px;
    }

}