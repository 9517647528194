@import '../../sass/utils.scss';

.optionCard {
    height: 100px;
    width: 100%;
    margin: 20px 0;
    font-weight: $title-font;
    background-color: #eeeded;
    border-radius: 10px;
    &__container {
        display: flex;
        height: 100px;
        align-items: center;
        padding: 0 30px
    }
    &__info {
        color: rgb(51, 51, 51);
    }
    &__info h3 {
        font-size: 14px;
        font-weight: 600;
    }

}